import { SimpleSection } from "../models/SimpleSection";

export const simpleSections: { [key: string]: SimpleSection } = {
    smartContractsOffchainJsCode: {
        header: "Universal Language Smart Contracts",
        textSections: [
            `
            Smart contracts, a fundamental aspect of VSC, are implemented in WebAssembly. 
            This provides critical advantages such as flexibility, language interopability, and access to a large selection of already existing tools and libraries. By utilizing WebAssembly, developers can write smart contracts in C++, Assemblyscript, Golang, rust or any language with support for WebAssembly. 
            `,
            `
            Currently, we officially support Assemblyscript via our dedicated SDK and contract template. 
            In the future we plan to add support for even more languages, creating a rich environment of accessibility and tooling. 
            Nothing stops developers from utilizing already built libraries in their language of choice. 
            No need to learn a new language to write contracts on VSC!
            `
        ]
    },
    // smartContractControlledMultisigs: {
    //     header: "Smart contract controlled multisigs", 
    //     textSections: ["Smart contracts possess the capability to control multisig operations and actively engage in on-chain activities. This functionality grants them the ability to participate in contract-specific operations conducted through multisignature accounts, enabling various functionalities and use cases within decentralized applications.",
    //         "One prominent use case where smart contracts leverage multisig control is in decentralized exchanges (DEXs) or similar platforms. In these scenarios, smart contracts can facilitate the movement of funds to and from multisignature accounts, allowing for secure and trustless transactions between users. Another important use case is HIVE account creation, this allows for accounts to be created entirely without a centralized backend. By utilizing multisigs, smart contracts can ensure that the appropriate signatures and authorization are in place before executing operations, enhancing the security and integrity of the process."]
    // },
    groupOfValidators: {
        header: "VSC uses technologies like DAGs, and ZK-proofs to scale the network.", 
        textSections: [

            `
            VSC uses advanced scaling technologies to provide a high degree of scalability for users of the network. Often overlooked by mainstream chains, DAGs, zero knowledge proofs, and other technology offer substantial advantages in scalability for a blockchain. VSC is nimble and modular, making it easy to adapt and implement 
            `,

            `
            In addition to utilizing advanced technologies, VSC aims to build it’s own layer to handle horizontal scaling. This allows VSC to split into separate (but interconnected) networks for a singular or multiple smart contracts. The VSC network provides ample capacity to build the applications of the future. We reject the notion of the Ethereum L2 model involving a large number of incompatible and often centralized L2 rollups. VSC is fully composable and we are actively working on building interopable layers with other L1s and L2s alike.
            `
        ]
    },
    ipfsStateAndTransactions: {
        header: "IPFS For Data Storage", 
        textSections: [
            
            "To store the state and transactions of smart contracts, VSC utilizes the InterPlanetary File System (IPFS), which provides a decentralized and highly scalable storage solution.",
            "IPFS enables VSC to store and retrieve large amounts of data like e.g. contract code or contract output, compared to a traditional Layer 1 storage system. This enhances the overall flexibility and efficiency of the system."]
    },
    highlyScalableDataStructures: {
        header: "Highly scalable data structures", 
        textSections: ["To meet the demands of scalability in VSC, highly scalable data structures are employed. These data structures play a crucial role in efficiently managing and storing the vast amount of data generated by smart contracts. By leveraging innovative techniques such as Directed Acyclic Graphs (DAGs), Merkle trees, and Conflict-free Replicated Data Types (CRDTs), VSC can achieve a high degree of scalability.",
            "DAGs are a powerful data structure used in VSC to represent and organize the relationships between different data elements. They provide a flexible and efficient way to handle complex data dependencies, enabling the execution of transactions in parallel and optimizing the overall throughput of the system. With DAGs, VSC can scale horizontally by distributing the data appendix across multiple nodes, ensuring efficient processing of transactions and improving overall network performance.",
            "Conflict-free Replicated Data Types (CRDTs), on the other hand, offer an effective solution to handle concurrent updates and ensure consistency across distributed systems. CRDTs enable multiple nodes in the network to independently update and modify the shared data without conflicts or inconsistencies. They provide strong eventual consistency guarantees, allowing smart contract platforms to scale effectively by accommodating concurrent operations and minimizing synchronization overhead.",
            "Additionally, Merkle trees are utilized for data integrity and verification. They provide a hierarchical structure where data elements are organized in a tree-like fashion, with each level of the tree containing a hash of the data elements below it. This allows for quick and secure verification of the integrity of the data stored. Merkle trees are particularly useful in scenarios where proof of data inclusion or absence is required, providing a robust and efficient way to validate the correctness of the stored data.",
            "By leveraging these highly scalable data structures, smart contract platforms can handle increasing transaction volumes and complex data interactions with ease. These data structures provide the foundation for efficient data management, parallel processing, data integrity verification, and conflict resolution in VSC, paving the way for scalability and improved performance in decentralized applications."]
    },
    witnessesKeepTrackMultisig: {
        header: "Layer 1 Gateway Wallet", 
        textSections: [
            `
            VSC is a L2 for the Hive blockchain. Like most L2s, this requires custody of L1 assets. Often times, this is via a centralized multisig controlled by a foundation or other entity behind the L2 network. VSC is completely different, validators of the VSC network have full control of L1 assets. This prevents a single entity from taking control of the network and draining all assets. Validators within VSC play a vital role in upholding the integrity of the gateway wallet and production of VSC blocks.
            `,
            `
            We are actively working on building even more advanced technology for safely and securely connecting L1 assets to VSC on Bitcoin and EVM.
            `
        ]
    },
    feelessSmartContractOperation: {
        header: "Zero fee/feeless smart contract operation", 
        textSections: ["Unlike traditional smart contract transactions that often incur fees for processing and verification, VSC's nearly feeless approach aims to make the deployment and execution of smart contracts more accessible and cost-effective. By minimizing transaction costs, it opens up new possibilities and encourages wider adoption of smart contracts across various areas and use cases."]
    },
}